







import { Component, Vue } from 'vue-property-decorator';

export default class About extends Vue {
  
}
